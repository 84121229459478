export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
        "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Please signin to <strong>Roojai Agent Portal</strong> and make your day.</p>"])},
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
        "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new password"])},
        "createNewPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a minimum of 12 characters, including uppercase letter, lowercase letter, and numbers"])},
        "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
        "verifyPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password, Please try again"])},
        "invalidResetPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Reset Password link"])},
        "invalidResetPasswordLinkDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait, you are redirecting to the login page."])},
        "invalidResetPasswordLinkDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or click the button below"])},
        "backToSignin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to sign-in"])},
        "resetPasswordSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password success"])},
        "resetPasswordSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been update"])}
      },
      "th": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยินดีต้อนรับ"])},
        "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>กรุณาเข้าสู่ระบบ <strong>เอเจ้นท์</strong> และเริ่มการขาย</p>"])},
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ทรหัสผ่าน"])},
        "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรหัสผ่านใหม่"])},
        "createNewPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้อักขระอย่างน้อย 12 ตัว รวมทั้งอักษรตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และตัวเลข"])},
        "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งรหัสผ่านใหม่"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันรหัสผ่าน"])},
        "verifyPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"])},
        "invalidResetPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงค์ตั้งค่ารหัสผ่านไม่ถูกต้อง"])},
        "invalidResetPasswordLinkDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณารอซักครู่ ระบบจะนำคุณไปยังหน้าเข้าสู่ระบบ"])},
        "invalidResetPasswordLinkDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือคลิกที่ปุ่มด้านล่างเพื่อเข้าสู่ระบบ"])},
        "backToSignin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่ลงชื่อเข้าใช้"])},
        "resetPasswordSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ทรหัสผ่านเรียบร้อยแล้ว"])},
        "resetPasswordSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่านของคุณได้รับการอัพเดตแล้ว"])}
      },
      "zh": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎!"])},
        "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>登陆<strong>Roojai路佳代理门户网站</strong> 开始您的新一天</p>"])},
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
        "createNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新密码"])},
        "createNewPasswordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少使用 12 个字符，包括大写字母、小写字母和数字"])},
        "setNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置新密码"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
        "verifyPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码无效，请重置"])},
        "invalidResetPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码链接无效"])},
        "invalidResetPasswordLinkDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍等，正在载入登陆界面"])},
        "invalidResetPasswordLinkDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或点击下方按钮"])},
        "backToSignin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回登陆"])},
        "resetPasswordSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码成功"])},
        "resetPasswordSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的密码已更新"])}
      }
    }
  })
}
