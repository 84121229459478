<style>
#reset-password .form-control{
  border-radius: 0.5rem;
}

#reset-password .page-header{
  height: 450px;
}

#reset-password #reset-password-container{
  margin-top:-200px;
}

@media (max-height: 728px) {
    #reset-password #reset-password-container{
      margin-top:-240px;
    }
}

/* #reset-password #reset-password-container .card{
  min-height: 367px;
} */


</style>

<template>
  <div id="reset-password">
    <nav class="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow-none my-3  navbar-transparent mt-5">
      <div class="container">
        <div class="navbar-brand font-weight-bolder ms-lg-0 ms-3 text-white" >
          {{$t("pageTitle")}}
        </div>
        <selectLang :pageName="'ResetPass'"></selectLang>
      </div>
    </nav>
    <!-- End Navbar -->

    <section class="mb-8">
      <div class="page-header align-items-start pt-5 pb-11 m-1 m-sm-3 m-md-3 border-radius-lg bg-pk">
        <span class="mask bg-gradient-dark opacity-2"></span>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center mx-auto">
              <h1 class="text-white mb-2 mt-5">{{$t("welcome")}}</h1>
              <p class="text-lead text-white" v-html="$t('pleaseSignIn')"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="container" id="reset-password-container">
        <div class="row">
          <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4">
                <div class="col-4 mx-auto">
                  <img src="img/roojai-logo.svg" alt="Roojai" />
                </div>
              </div>
              
              <div id="reset-passowrd-body" class="card-body" v-if="!isShowSuccess">
                <div class="text-center d-block justify-content-center" style="line-height: 266px; vertical-align: middle;" v-if="isLoading">
                  <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
                </div>
                <div id="valid-reset-password-link" v-else-if="!isLoading && isValidResetPasswordLink">
                  <p><strong>{{$t('createNewPassword')}}</strong></p>
                  <p><small>{{$t('createNewPasswordDesc')}}</small></p>

                  <div class="mb-3">
                      <input type="password" v-model.trim="password" id="pass" class="form-control" :placeholder="$t('password')">
                  </div>
                  <div class="mb-3">
                      <input type="password" v-model.trim="confirmPassword" id="pass" class="form-control" :placeholder="$t('confirmPassword')">
                  </div>

                  <div class="text-danger text-center mt-3" v-if="verifyPasswordError">
                    <small>{{ $t('verifyPasswordError') }}</small>
                  </div>

                  <div class="text-center">
                    <button type="button" :disabled="isProcessingSetNewPassword || !password || !confirmPassword" class="btn bg-gradient-info w-100 my-3 mb-2" @click="setNewPassword()">
                      {{$t('setNewPassword')}} <span class="spinner-border spinner-border-sm" v-if="isProcessingSetNewPassword" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
                <div id="invalid-reset-password-link" v-else>
                  <p><strong>{{$t('invalidResetPasswordLink')}}</strong></p>
                  <p><small>{{$t('invalidResetPasswordLinkDesc')}}</small></p>
                  <p><small>{{$t('invalidResetPasswordLinkDesc2')}}</small></p>
                  
                  <div class="text-center">
                    <button type="button" class="btn bg-gradient-info w-100 my-3 mb-2" @click="$router.push('/signin');">
                      {{$t('backToSignin')}} <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div id="reset-pasword-success" class="card-body" v-else>
                <div class="text-center">
                  <p><strong>{{$t('resetPasswordSuccess')}}</strong></p>
                  <p><small>{{$t('resetPasswordSuccessDesc')}}</small></p>
                  <svg viewBox="0 0 512 512" width="70" height="70" fill="#82d616"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                  <div class="text-center mt-3">
                    <button type="button" class="btn bg-gradient-info w-100 my-3 mb-2" @click="$router.push('/signin');">
                      {{$t('backToSignin')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import mixin from "@/components/Mixin";
import Global from "@/stores/GlobalVariables.js";
import selectLang from "@/components/SelectLang";

export default {
    mixins: [mixin],
    name: "ResetPassword",
    components: { selectLang },
    data: function() {
        return {
            Global,
            password: "",
            confirmPassword: "",
            isProcessingSetNewPassword: false,
            isValidResetPasswordLink: false,
            verifyPasswordError: false,
            isShowSuccess: false,
            isLoading: true
        }
    },
    async created() {
      var vm = this;
      await this.sfMcsApiUserLogin();
      
      if(vm.$route.query.lang){
          Global.locale = vm.$route.query.lang;
      }
      if(this.$route.query.key && await this.verifyLink(this.$route.query.key)){
        this.isValidResetPasswordLink = true;
      }else{
        this.isValidResetPasswordLink = false;
        setTimeout( function() {
          vm.$router.push({ path: '/signin'});
        },5000);
      }
      this.isLoading = false;
    },
    methods: {
      verifyLink: async function(key){
        var response = await axios.get(Global.apiUrl + "/broker-details/verifyLink?key=" + encodeURIComponent(key), {
        headers : {
          Authorization : "Bearer " + Global.apiUser.jwt
        }
      });
        return response.data.success;
      },
      setNewPassword: async function(){
        this.verifyPasswordError = false;
        if (this.validatePassword(this.password, this.confirmPassword) == false) {
          this.verifyPasswordError = true;
          return false;
        }
        this.logActivity(this.emailForgotPassword, "Change password", "Submit change password", null);
        this.isProcessingSetNewPassword = true;
        var response = await axios.post(Global.apiUrl + "/broker-details/resetPassword", 
        {
            password: this.password,
            cfPassword: this.confirmPassword,
            type: "reset",
            key: this.$route.query.key
        }, 
        {
            headers : {
              Authorization : "Bearer " + Global.apiUser.jwt
            }
        });
        if(response.data.success){
          this.verifyPasswordError = false;
          this.isShowSuccess = true;
        }else{
          this.verifyPasswordError = true;
        }
        this.isProcessingSetNewPassword = false;
      }
    }
}
</script>

<i18n>
{
  "en":{
    "welcome": "Welcome!",
    "pleaseSignIn": "<p>Please signin to <strong>Roojai Agent Portal</strong> and make your day.</p>",
    "resetPassword": "Reset Password",
    "createNewPassword": "Create a new password",
    "createNewPasswordDesc": "Use a minimum of 12 characters, including uppercase letter, lowercase letter, and numbers",
    "setNewPassword": "Set new password",
    "password": "Password",
    "confirmPassword": "Confirm password",
    "verifyPasswordError": "Invalid password, Please try again",
    "invalidResetPasswordLink": "Invalid Reset Password link",
    "invalidResetPasswordLinkDesc": "Please wait, you are redirecting to the login page.",
    "invalidResetPasswordLinkDesc2": "Or click the button below",
    "backToSignin": "Back to sign-in",
    "resetPasswordSuccess": "Reset password success",
    "resetPasswordSuccessDesc": "Your password has been update"
  },
  "th":{
    "welcome": "ยินดีต้อนรับ",
    "pleaseSignIn": "<p>กรุณาเข้าสู่ระบบ <strong>เอเจ้นท์</strong> และเริ่มการขาย</p>",
    "resetPassword": "รีเซ็ทรหัสผ่าน",
    "createNewPassword": "สร้างรหัสผ่านใหม่",
    "createNewPasswordDesc": "ใช้อักขระอย่างน้อย 12 ตัว รวมทั้งอักษรตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และตัวเลข",
    "setNewPassword": "ตั้งรหัสผ่านใหม่",
    "password": "รหัสผ่าน",
    "confirmPassword": "ยืนยันรหัสผ่าน",
    "verifyPasswordError": "รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
    "invalidResetPasswordLink": "ลิงค์ตั้งค่ารหัสผ่านไม่ถูกต้อง",
    "invalidResetPasswordLinkDesc": "กรุณารอซักครู่ ระบบจะนำคุณไปยังหน้าเข้าสู่ระบบ",
    "invalidResetPasswordLinkDesc2": "หรือคลิกที่ปุ่มด้านล่างเพื่อเข้าสู่ระบบ",
    "backToSignin": "กลับไปที่ลงชื่อเข้าใช้",
    "resetPasswordSuccess": "รีเซ็ทรหัสผ่านเรียบร้อยแล้ว",
    "resetPasswordSuccessDesc": "รหัสผ่านของคุณได้รับการอัพเดตแล้ว"
  },
  "zh":{
    "welcome": "欢迎!",
    "pleaseSignIn": "<p>登陆<strong>Roojai路佳代理门户网站</strong> 开始您的新一天</p>",
    "resetPassword": "重置密码",
    "createNewPassword": "创建新密码",
    "createNewPasswordDesc": "至少使用 12 个字符，包括大写字母、小写字母和数字",
    "setNewPassword": "设置新密码",
    "password": "密码",
    "confirmPassword": "确认密码",
    "verifyPasswordError": "密码无效，请重置",
    "invalidResetPasswordLink": "重置密码链接无效",
    "invalidResetPasswordLinkDesc": "请稍等，正在载入登陆界面",
    "invalidResetPasswordLinkDesc2": "或点击下方按钮",
    "backToSignin": "返回登陆",
    "resetPasswordSuccess": "重置密码成功",
    "resetPasswordSuccessDesc": "您的密码已更新"
  }
}
</i18n>